import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetAllAssignmentViewItem } from 'edu-application/dist/assignments/get-all-assignments/get-all-assignments.viewmodel'
import { GetAssignmentByIdViewModel } from 'edu-application/dist/assignments/get-assignment-by-id/get-assignment-by-id.viewmodel';
import { CreateAssignmentViewModel } from 'edu-application/dist/assignments/create-assignment/create-assignment.viewmodel';
import { AddQuestionToAssignmentRequest } from 'edu-application/dist/assignments/add-question-to-assignment/add-question-to-assignment.request';
import { EditQuestionFromAssignmentRequest } from 'edu-application/dist/assignments/edit-question-from-assignment/edit-question-from-assignment.request';
import { RemoveQuestionFromAssignmentRequest } from 'edu-application/dist/assignments/remove-question-from-assignment/remove-question-from-assignment.request';
import { GetStudentAssignmentsViewModel } from 'edu-application/dist/assignments/get-student-assignments/get-student-assignments.viewmodel';
import { GetStudentAssignmentsRequest } from 'edu-application/dist/assignments/get-student-assignments/get-student-assignments.request';
import { GetAllGroupAssignmentsViewModel } from 'edu-application/dist/assignments/get-all-group-assignments/get-all-group-assignments.viewmodel';
import { AssignGroupRequest } from 'edu-application/dist/assignments/assign-group-to-assignment/assign-group.request';
import { UnAssignGroupRequest } from 'edu-application/dist/assignments/unassign-group-to-assignment/unassign-group.request'
import { AssignStudentRequest } from 'edu-application/dist/assignments/assign-student-to-assignment/assign-student.request';
import { AssignStudentViewModel } from 'edu-application/dist/assignments/assign-student-to-assignment/assign-student.viewmodel';
import { AssignGroupViewModel } from 'edu-application/dist/assignments/assign-group-to-assignment/assign-group.viewmodel';
import { ToggleGroupAssignmentStateRequest } from 'edu-application/dist/assignments/toggle-assignment-state-for-group/toggle-assignment-state.request';
import { ToggleGroupAssignmentViewModel } from 'edu-application/dist/assignments/toggle-assignment-state-for-group/toggle-assignment-state.viewmodel';
import { ToggleIgnoreReviewRequest } from 'edu-application/dist/assignments/toggle-ignore-review/toggle-ignore-review.request';
import { SetHomeworkDeadlineRequest } from 'edu-application/dist/assignments/set-homework-deadline/set-homework-deadline.request';
import { SetHomeworkDeadlineViewModel } from 'edu-application/dist/assignments/set-homework-deadline/set-homework-deadline.viewmodel';
import { CreateAssignmentRequest } from 'edu-application/dist/assignments/create-assignment/create-assignment.request';
import { AddCorrectAnswerRequest } from 'edu-application/dist/assignments/add-correct-answer/add-correct-answer.request';
import { EditAssignmentRequest } from 'edu-application/dist/assignments/edit-assignment/edit-assignment.request';
import { DuplicateAssignmentRequest } from 'edu-application/dist/assignments/duplicate-assignment/duplicate-assignment.request';
import { ToggleGroupAssignmentPublishStateRequest } from 'edu-application/dist/assignments/toggle-publish-state-for-group/toggle-publish-state.request'
import { ToggleGroupAssignmentPublishViewModel } from 'edu-application/dist/assignments/toggle-publish-state-for-group/toggle-publish-state.viewmodel'
import { environment } from 'src/environments/environment';
import { MeetingAssignmentViewItem, MeetingAssignmentViewModel } from 'edu-application/dist/assignments/meeting-link-assignments/shared/meeting-assignment.viewmodel';
import { ToggleMeetingAssignmentStateRequest } from 'edu-application/dist/assignments/meeting-link-assignments/toggle-meeting-assignments/toggle-meeting-assignment.request'
import { AssignMeetingsForAssignmentRequest } from 'edu-application/dist/assignments/meeting-link-assignments/assign-meetings-for-assignment/assign-meetings-for-assignments.request'
import { RemoveMeetingAssignmentRequest } from 'edu-application/dist/assignments/meeting-link-assignments/remove-meeting-assignment/remove-meeting-assignment.request'
@Injectable({
  providedIn: 'root'
})
export class AssignmentsService {


  constructor(private _client: HttpClient) { }

  async duplicateAssignment(request: DuplicateAssignmentRequest) {
    return this._client.post<CreateAssignmentViewModel>(`${environment.backend_url}/assignments/${request.assignmentId}/duplicate`, request).toPromise();
  }

  async getAssignments(archived?: boolean): Promise<GetAllAssignmentViewItem[]> {
    return this._client.get<GetAllAssignmentViewItem[]>(`${environment.backend_url}/assignments?archive=${archived ?? false}`).toPromise();
  }

  async getAssignment(id: string, live: boolean = false): Promise<GetAssignmentByIdViewModel> {
    return this._client.get<GetAssignmentByIdViewModel>(`${environment.backend_url}/assignments/${id}`).toPromise();
  }

  async addAssignment(assignment: CreateAssignmentRequest): Promise<CreateAssignmentViewModel> {
    return this._client.post<CreateAssignmentViewModel>(`${environment.backend_url}/assignments`, assignment).toPromise();
  }

  async addQuestionToAssignment(questionRequest: AddQuestionToAssignmentRequest, id: string): Promise<string> {
    return this._client.post<string>(`${environment.backend_url}/assignments/${id}/questions`, questionRequest).toPromise();
  }

  async editQuestion(editRequest: EditQuestionFromAssignmentRequest, id: string): Promise<void> {
    return this._client.put<void>(`${environment.backend_url}/assignments/${id}/questions`, editRequest).toPromise();
  }

  async removeQuestion(removeRequest: RemoveQuestionFromAssignmentRequest): Promise<void> {
    return this._client.delete<void>(`${environment.backend_url}/assignments/${removeRequest.assignmentId}/questions`, {
      body: removeRequest
    }).toPromise();
  }

  async getStudentAssignments(request: GetStudentAssignmentsRequest): Promise<GetStudentAssignmentsViewModel> {
    return this._client.get<GetStudentAssignmentsViewModel>(`${environment.backend_url}/assignments/student?userName=${request.userName}`).toPromise();
  }

  async getGroupAssignments(assignmentId?: string): Promise<GetAllGroupAssignmentsViewModel> {
    if (assignmentId == undefined) {
      return await this._client.get<GetAllGroupAssignmentsViewModel>(`${environment.backend_url}/assignments/groups/all`).toPromise();
    }
    return await this._client.get<GetAllGroupAssignmentsViewModel>(`${environment.backend_url}/assignments/groups/${assignmentId}`).toPromise();
  }

  async assignGroupToAssignment(assignmentId: string, groupId: string): Promise<AssignGroupViewModel[]> {
    let request: AssignGroupRequest = {
      assignmentId: assignmentId,
      groupId: groupId
    };
    return this._client.post<AssignGroupViewModel[]>(`${environment.backend_url}/assignments/${assignmentId}/groups`, request).toPromise();
  }

  async toggleAssignment(groupId: string, groupAssignmentId: string, state: boolean): Promise<ToggleGroupAssignmentViewModel> {
    let request: ToggleGroupAssignmentStateRequest = {
      groupId: groupId,
      assignmentId: groupAssignmentId,
      assignmentState: state
    };
    return this._client.put<ToggleGroupAssignmentViewModel>(`${environment.backend_url}/assignments/${groupAssignmentId}`, request).toPromise();
  }

  async togglePublish(groupId: string, groupAssignmentId: string, state: boolean): Promise<ToggleGroupAssignmentPublishViewModel> {
    let request: ToggleGroupAssignmentPublishStateRequest = {
      groupId: groupId,
      assignmentId: groupAssignmentId,
      publishState: state
    };
    return this._client.put<ToggleGroupAssignmentPublishViewModel>(`${environment.backend_url}/assignments/${groupAssignmentId}/publish`, request).toPromise();
  }

  async toggleIgnoreReview(groupId: string, groupAssignmentId: string, state: boolean): Promise<void> {
    let request: ToggleIgnoreReviewRequest = {
      groupId: groupId,
      assignmentId: groupAssignmentId,
      ignoreState: state
    };
    return this._client.put<void>(`${environment.backend_url}/assignments/${groupAssignmentId}/ignore-review`, request).toPromise()
  }

  async setHomeworkDeadline(groupAssignmentId: string, deadline: Date): Promise<SetHomeworkDeadlineViewModel> {
    let request: SetHomeworkDeadlineRequest = {
      groupAssignmentId: groupAssignmentId,
      deadline: deadline
    };
    return this._client.put<SetHomeworkDeadlineViewModel>(`${environment.backend_url}/assignments/${groupAssignmentId}/deadline`, request).toPromise();
  }

  async addCorrectAnswer(assignmentId: string, request: AddCorrectAnswerRequest): Promise<void> {
    return this._client.post<void>(`${environment.backend_url}/assignments/${assignmentId}/marking-scheme`, request).toPromise();
  }

  async assignStudentToGroupAssignment(request: AssignStudentRequest): Promise<AssignStudentViewModel> {
    return this._client.post<AssignStudentViewModel>(`${environment.backend_url}/assignments/${request.groupAssignmentId}/students`, request).toPromise();
  }

  async editAssignment(request: EditAssignmentRequest): Promise<void> {
    return this._client.put<void>(`${environment.backend_url}/assignments/${request.id}/edit`, request).toPromise();
  }

  async unAssignGroupToAssignment(groupAssignmentId: string, assignmentId: string, groupId: string): Promise<void> {
    let request: UnAssignGroupRequest = {
      assignmentId: assignmentId,
      groupId: groupId,
      groupAssignmentId: groupAssignmentId
    };
    return this._client.put<void>(`${environment.backend_url}/assignments/${groupAssignmentId}/unassign`, request).toPromise();
  }

  async getMeetingAssignments(assignmentId: string): Promise<MeetingAssignmentViewModel> {
    return this._client.get<MeetingAssignmentViewModel>(`${environment.backend_url}/meeting-assignments?assignmentId=${assignmentId}`).toPromise();
  }

  async toggleMeetingAssignment(assignmentId: string, meetingId: string, isOpen: boolean): Promise<MeetingAssignmentViewItem> {
    let request: ToggleMeetingAssignmentStateRequest = {
      meetingId: meetingId,
      assignmentId: assignmentId,
      isOpen: isOpen
    };
    return this._client.put<MeetingAssignmentViewItem>(`${environment.backend_url}/meeting-assignments`, request).toPromise();
  }

  async assignMeetingToAssignment(assignmentId: string, meetingsIds: string[]): Promise<MeetingAssignmentViewModel> {
    let request: AssignMeetingsForAssignmentRequest = {
      meetingsIds: meetingsIds,
      assignmentId: assignmentId,
    };
    return this._client.post<MeetingAssignmentViewModel>(`${environment.backend_url}/meeting-assignments`, request).toPromise();
  }

  async deleteMeetingAssignment(assignmentId: string, meetingId: string): Promise<MeetingAssignmentViewItem> {
    return this._client.delete<MeetingAssignmentViewItem>(`${environment.backend_url}/meeting-assignments?assignmentId=${assignmentId}&meetingId=${meetingId}`).toPromise();
  }
}
