import { Directive, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[eduBlockCopy]'
})
export class BlockCopyDirective {

  constructor(
    private snackBar: MatSnackBar
  ) { }
  private readonly signature: string = `\n\n-- Signature: Copied from Translator ${environment.org}`
  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (environment.org === 'ICTeam') {
      return;
    }
    let data = e.clipboardData.getData('text')
    if (!data.includes(this.signature)) {
      this.snackBar.open('You are not allowed to paste', undefined, { duration: 1 * 3600 })
    } else {
      (e.target as any).value = data.split(this.signature)[0];
    }
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
    this.snackBar.open('You are not allowed to copy', undefined, { duration: 1 * 3600 })
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
    this.snackBar.open('You are not allowed to cut', undefined, { duration: 1 * 3600 })
  }

}
