import { Component, Input } from '@angular/core';

@Component({
  selector: 'edu-submission-date',
  templateUrl: './submission-date.component.html',
  styleUrls: ['./submission-date.component.scss']
})
export class SubmissionDateComponent {
  @Input() date: Date = new Date();
  submissionDate!: string; // Expected format: ISO string (e.g., "2025-05-17T05:00:00Z")


  ngOnChanges(): void {
    if (this.date === null) {
      return;
    }
    if (!(this.date instanceof Date)) {
      this.date = new Date(this.date);
    }
    this.submissionDate = this.date.toISOString();
  }
  get formattedDate(): string {
    const date = new Date(this.submissionDate);
    return date.toLocaleDateString('en-GB', { weekday: 'long', day: '2-digit', month: 'long' });
  }

  get formattedTime(): string {
    const date = new Date(this.submissionDate);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  }
}