<div class="paginator">
    <button *ngIf="this.showFirstLastButtons" title="previous" class="navigation-button" (click)="this.previous()">
        <img alt="arrow-left" src="assets/assignments/angle-circle-left.svg" />
    </button>
    <div class="ellipse-container">
        <div *ngFor="let page of [].constructor(this.getNumberOfPages()); let i = index" class="ellipse"
            [class.selected]="i === this.pageIndex"></div>
    </div>
    <button *ngIf="this.showFirstLastButtons" title="next" class="navigation-button" (click)="this.next()">
        <img alt="arrow-right" src="assets/assignments/angle-circle-right.svg" />
    </button>
</div>