import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as marked from "marked"
import { BehaviorSubject } from 'rxjs';

// Interface for highlight information
export interface HighlightInfo {
  text: string;
  startPosition: number;
  length: number;
}

@Component({
  selector: 'markdown-view',
  templateUrl: './markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarkdownViewerComponent implements OnInit, OnChanges {
  @Input('data') data: string;
  @Output() textHighlighted = new EventEmitter<HighlightInfo | null>();

  private highlightSubject = new BehaviorSubject<HighlightInfo | null>(null);
  public highlight$ = this.highlightSubject.asObservable();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    var md = marked.setOptions({});
    this.convertedData = md.parse(this.data)
  }
  convertedData: string;

  ngOnInit(): void {
  }

  onTextSelect(): void {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount <= 0) {
      return;
    }

    const highlightedText = selection.toString();
    if (!highlightedText.trim()) {
      this.highlightSubject.next(null);
      this.textHighlighted.emit(null);
      return;
    }

    const range = selection.getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(document.querySelector('.preview'));
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const startPosition = preSelectionRange.toString().length;

    const highlightInfo: HighlightInfo = {
      text: highlightedText,
      startPosition: startPosition,
      length: highlightedText.length
    };

    this.highlightSubject.next(highlightInfo);
    this.textHighlighted.emit(highlightInfo);
  }
}
