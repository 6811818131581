import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as marked from "marked";
import { BehaviorSubject } from 'rxjs';
import { CheckListItemViewmodel, HighlightInfo } from 'edu-application/dist/assignments/checklists/get-assignment-checklist/get-assignment-checklist.viewmodel';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'highlighted-markdown-view',
    templateUrl: './highlighted-markdown-viewer.component.html',
    styleUrls: ['./highlighted-markdown-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HighlightedMarkdownViewerComponent implements OnInit, OnChanges {
    @Input('data') data: string;
    @Input() highlights: HighlightInfo[] = [];
    @Output() textHighlighted = new EventEmitter<HighlightInfo | null>();
    @Input() subject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    @Input() checkListId: string;

    private highlightSubject = new BehaviorSubject<HighlightInfo | null>(null);
    public highlight$ = this.highlightSubject.asObservable();

    public convertedData: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.renderContent();
    }

    ngOnInit(): void {
        this.highlightSubject.subscribe((highlight) => {
            this.textHighlighted.emit(highlight);
        });
        this.subject.subscribe((value) => {
            if (this.checkListId && value === this.checkListId) {
                this.renderContent();
            }
        })
        this.renderContent();
    }

    private renderContent(): void {
        if (!this.data) return;
        const markdownParser = marked.setOptions({});
        let htmlContent = markdownParser.parse(this.data);

        // Apply highlights if they exist
        if (this.highlights && this.highlights.length > 0) {
            htmlContent = this.applyHighlights(this.data, this.highlights);
            htmlContent = markdownParser.parse(htmlContent);
        }

        this.convertedData = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
    }

    private applyHighlights(text: string, highlights: HighlightInfo[]): string {
        if (!highlights || highlights.length === 0) {
            return text;
        }

        // Sort highlights by start position (descending to prevent index shifts)
        const sortedHighlights = [...highlights].sort((a, b) => b.startPosition - a.startPosition);

        let result = text;

        for (const highlight of sortedHighlights) {
            const before = result.substring(0, highlight.startPosition);
            const highlightedText = result.substring(highlight.startPosition, highlight.startPosition + highlight.length);
            const after = result.substring(highlight.startPosition + highlight.length, result.length);

            // Use a special markdown syntax that will be preserved during parsing
            // We'll use a custom span with a class that will be styled in CSS
            result = before + '<span class="highlight-marker">' + highlightedText + '</span>' + after;
        }
        return result;
    }

    onTextSelect(): void {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount <= 0) {
            this.highlightSubject.next(null);
            return;
        }

        const highlightedText = selection.toString();
        if (!highlightedText.trim()) {
            this.highlightSubject.next(null);
            return;
        }

        // Get the selected text from the window selection
        const selectedText = highlightedText.trim();

        // Escape special regex characters in the selected text
        const escapedText = selectedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Create a regex pattern that will strip markdown characters from the source text
        // and then compare with our selected text
        // First, get a clean version of the markdown source by removing common markdown syntax
        const cleanSource = this.data.replace(/(\*|_|~|`|#|\||\!|\[|\]|\(|\)|<|>)/g, '');

        // Find the position in the clean source
        const cleanRegex = new RegExp(escapedText, 'g');
        const cleanMatch = cleanRegex.exec(cleanSource);

        if (!cleanMatch) {
            console.warn('Selected text not found in cleaned markdown content');
            this.highlightSubject.next(null);
            return;
        }

        // Now find the corresponding position in the original markdown
        const cleanIndex = cleanMatch.index;

        // Map the clean index back to the original markdown by counting 
        // non-markdown characters up to the match position
        let originalIndex = 0;
        let nonMarkdownCount = 0;

        while (nonMarkdownCount < cleanIndex && originalIndex < this.data.length) {
            // Skip markdown characters in counting
            if (!/(\*|_|~|`|#|\||\!|\[|\]|\(|\)|<|>)/.test(this.data[originalIndex])) {
                nonMarkdownCount++;
            }
            originalIndex++;
        }

        // Calculate the length including markdown characters
        let originalLength = 0;
        let remainingChars = selectedText.length;
        let currentIndex = originalIndex;

        while (remainingChars > 0 && currentIndex < this.data.length) {
            if (!/(\*|_|~|`|#|\||\!|\[|\]|\(|\)|<|>)/.test(this.data[currentIndex])) {
                remainingChars--;
            }
            originalLength++;
            currentIndex++;
        }

        const highlightInfo: HighlightInfo = {
            text: selectedText,
            startPosition: originalIndex,
            length: originalLength
        };

        // Check if this highlight conflicts with any existing highlight
        if (this.highlights && this.highlights.length > 0) {
            const hasConflict = this.highlights.some(existing =>
                (highlightInfo.startPosition >= existing.startPosition &&
                    highlightInfo.startPosition < (existing.startPosition + existing.length)) ||
                ((highlightInfo.startPosition + highlightInfo.length) > existing.startPosition &&
                    (highlightInfo.startPosition + highlightInfo.length) <= (existing.startPosition + existing.length)) ||
                (highlightInfo.startPosition <= existing.startPosition &&
                    (highlightInfo.startPosition + highlightInfo.length) >= (existing.startPosition + existing.length))
            );

            if (hasConflict) {
                console.warn('Highlight conflicts with an existing highlight');
                this.highlightSubject.next(null);
                return;
            }
        }

        this.highlightSubject.next(highlightInfo);
    }
}
