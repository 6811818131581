import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'edu-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnChanges, OnInit {
  @Input() dateTime: Date = new Date();

  clockHandsPath: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTime']) {
      this.updateClockHands();
    }
  }

  ngOnInit(): void {
    this.updateClockHands();
  }

  updateClockHands(): void {
    const date = this.dateTime;

    // Get hours, minutes and seconds
    const hours = date.getHours() % 12;
    const minutes = date.getMinutes();

    // Calculate angles for hands
    // Hour hand: 30 degrees per hour + 0.5 degrees per minute
    const hourAngle = (hours * 30) + (minutes * 0.5);
    // Minute hand: 6 degrees per minute
    const minuteAngle = minutes * 6;

    // Calculate the coordinates for the hands
    const hourX = 12 + 5.5 * Math.sin(this.toRadians(hourAngle));
    const hourY = 12 - 5.5 * Math.cos(this.toRadians(hourAngle));

    const minuteX = 12 + 7 * Math.sin(this.toRadians(minuteAngle));
    const minuteY = 12 - 7 * Math.cos(this.toRadians(minuteAngle));

    // Create SVG path
    this.clockHandsPath = `M${hourX.toFixed(2)},${hourY.toFixed(2)}L12,12L${minuteX.toFixed(2)},${minuteY.toFixed(2)}`;
  }

  private toRadians(degrees: number): number {
    return degrees * Math.PI / 180;
  }
}
