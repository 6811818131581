import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'edu-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() pageSize: number;
  @Input() length: number;

  @Output() onPageChange = new EventEmitter<number>();

  /** The zero-based page index of the displayed list of items. Defaulted to 0. */
  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: number) {
    this._pageIndex = Math.max(value || 0, 0);
    this._changeDetectorRef.markForCheck();
    this.onPageChange.emit(this._pageIndex);
  }
  private _pageIndex = 0;


  @Input()
  hidePageSize: boolean = false;

  /** Whether to show the first/last buttons UI to the user. */
  @Input()
  showFirstLastButtons: boolean = true;

  /** Whether the paginator is disabled. */
  @Input()
  disabled: boolean = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
  }

  previous() {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;
    // this._emitPageEvent(previousPageIndex);
  }

  next() {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;
    // this._emitPageEvent(previousPageIndex);
  }

  hasNextPage() {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize != 0;
  }

  /** Calculate the number of pages */
  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }

    return Math.ceil(this.length / this.pageSize);
  }

  /** Whether there is a previous page. */
  hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize != 0;
  }
}
