<div class="edu-page" [class.pseucode-navbar]="this.currentRoute?.name == 'Pseudocode'">
    <div class="navbar top-pad" [class.dark-mode]='this.isDark'
        [class.navbar-collapsed]="this.currentRoute?.name == 'Pseudocode'" [class.hide]="!this.sideNavOpen">
        <edu-logo class="logo" [collapsed]="this.currentRoute?.name == 'Pseudocode'"></edu-logo>
        <div class="routes" [class.collapsed-routes]="this.currentRoute?.name == 'Pseudocode'">
            <app-navbar-item [collapsed]="this.currentRoute?.name == 'Pseudocode'" *ngFor="let route of this.viewableRoutes"
                [route]="route"></app-navbar-item>
        </div>
    </div>
    <div class="background">
        <!-- <app-ramadan-header class="no-padding"></app-ramadan-header> -->
        <div class="header top-pad" [class.dark-mode]='this.isDark'>
            <button class="nav-button" mat-icon-button (click)="this.toggleSideNav()"><mat-icon>menu</mat-icon></button>
            <edu-header-title [darkMode]="this.isDark" *ngIf="this.currentRoute != null"
            [route]="this.currentRoute"></edu-header-title>
            <div class="profile-button-container">
                <edu-points-summary [darkMode]="this.isDark" *ngIf="this.environment.org == 'ICTeam' && !this.isAdmin"
                type="points"></edu-points-summary>
                <edu-points-summary [darkMode]="this.isDark" *ngIf="this.environment.org == 'CSTeam'  && !this.isAdmin"
                type="trophy" (click)="this.goToTrophies()"></edu-points-summary>
                <profile-button></profile-button>
            </div>
        </div>
        <div class="actual-page" (click)="this.toggleSideNav(false)"
            [class.translator]="this.currentRoute?.name == 'Pseudocode'" [class.dark-mode]="isDark"
            [class.actual-submission]="checkPage()">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>


<div class="date-time-field" subscriptSizing="dynamic" style="position: absolute; top: -10000px; left: -10000px; visibility: hidden;">
  <input matInput [matDatepicker]="fauxPicker">
  <mat-datepicker-toggle matIconSuffix [for]="fauxPicker"></mat-datepicker-toggle>
  <mat-datepicker #fauxPicker></mat-datepicker>
</div>
