import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        try {

          this.loginService.logout();
          let url = this.router.routerState.snapshot.url.includes('login') ? undefined : this.router.routerState.snapshot.url;
          // auto logout if 401 response returned from api
          this.router.navigate(['/login'], {
            queryParams: {
              redirect_to: url
            }
          });
        } catch (e) {
          throwError(err);
        }
      }

      const error = err.error.message || err.statusText;
      return throwError(err);
    }))
  }
}
