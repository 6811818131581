import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ViewStudentsViewItem } from 'edu-application/dist/students/view-students/view-students.viewmodel';
import { BaseTimingViewmodel } from 'edu-application/dist/timings/base/timings.viewmodel';
import { AddExtraTimingExcuseRequest } from 'edu-application/dist/students/add-extra-timing/add-extra-timing.request';
import { TimingsService } from 'src/app/timings/timings.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'edu-student-schedule',
  templateUrl: './student-schedule.component.html',
  styleUrls: ['./student-schedule.component.scss']
})
export class StudentScheduleComponent implements OnInit {
  isloading: boolean = false;
  constructor(
    private service: TimingsService,
    private snackBar: MatSnackBar,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: ViewStudentsViewItem
  ) { }

  ngOnInit(): void {
    this.getSchedule();
  }
  allTimings: BaseTimingViewmodel[];
  studentTimings: BaseTimingViewmodel[];
  selectedTimings: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
  async getSchedule() {
    try {
      this.isloading = true;
      [this.allTimings, this.studentTimings] = await Promise.all([this.service.getTimings(), this.service.getStudentTimings({ userName: this.data.userName })]);
      let ids = new Set(this.studentTimings.map(s => s.id))
      this.allTimings = this.allTimings.filter(s => !ids.has(s.id));
    } catch (e) {

    } finally {
      this.isloading = false;
    }
  }

  getDay(day: number) {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]
  }

  getTime(time: Date) {
    time = new Date(time);
    return time.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  async addExcuse() {
    if (!this.selectedTimings.valid) {
      return;
    }
    let timing: BaseTimingViewmodel = this.selectedTimings.value;
    let request: AddExtraTimingExcuseRequest = {
      userName: this.data.userName,
      timingId: timing.id.toString()
    }
    try {
      this.isloading = true;
      await this.service.setStudentExcuse(request);
      let oldTiming = this.studentTimings.findIndex(s => s.classNumber === timing.classNumber);
      if (oldTiming != -1) {
        this.studentTimings[oldTiming] = timing;
      } else {
        this.studentTimings.push(timing);
      }
    } catch (e) {
      this.snackBar.open(e.error.message, '✖️', { duration: 5000, horizontalPosition: 'center', 'verticalPosition': 'top' });
    } finally {
      this.isloading = false;
    }
  }

}
